@import './styles/Variables.scss';
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #595959;
}

main {
  // margin: 0 6rem;
  background-color: $blanco;
}

// @media  screen and (max-width: 840px) {
//   main {
//     margin: 0 2rem;
//   }
// }

// @media  screen and (max-width: 535px) {
//   main {
//     margin: 0;
//   }

// }