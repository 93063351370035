@import '../../styles/Variables.scss';

header {
    position: relative;
    z-index: 1;
    .nav {
        background-color: $blanco;
        height: $heigth-navbar-large;
        padding: 0 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px -10px 10px 4px #232323;
        .ul-linksNav {
            width: 80%;
            display: flex;
            padding: 0 10px;
            justify-content: space-between;
            list-style: none;
            .linksNav {
                font-style: normal;
                text-decoration: none;
                color: $negro;
                cursor: pointer;
            }
        }
    }
}


@media  screen and (max-width: $tablet) {
    header {
        .nav {
            padding: 0 2rem;
            height: $heigth-navbar-small;
            .btn-nav {
                padding: 0 10px;
                color: $negro;
                background-color: transparent;
                border: none;
                font-size: 40px;
            }
        }
    }
    .ul-nav-bar {
        opacity: 1;
        transition: opacity 0.3s linear;
        position: absolute;
        z-index: 2;
        width: 100%;
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.503);
        list-style: none;
        .li-nav-bar {
            padding: 13px 0;
            color: $blanco;
            cursor: pointer;
        }
    }
    .disable {
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s linear;
    }
}