@import '../../styles/Variables.scss';


.logo-navbar {
    width: 7.5rem;
}

// @media  screen and (max-width: $tablet) {
//     .h1-logo {
//         font-size: 25px;
//     }
// }