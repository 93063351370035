@import '../../styles/Variables.scss';

.main-home {
    height: calc(100vh - $heigth-navbar-large);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-in-construction {
        pointer-events: none;
        user-select: none;
        height: calc(100vh - $heigth-navbar-large - 10rem);
    }
    .p1 {
        padding: 1rem;
        font-weight: bold;
        font-size: 30px;
        text-transform: uppercase;
    }
    .div-contact {
        display: flex;
        justify-content: center;
        align-items: center;
        .p2 {
            @extend .texto-parrafo;
            .a-contact {
                @extend .texto-link;
            }
        }
    }
}


//* MEDIAS QUERIES TABLET Y MOBILE

@media  screen and (max-width: $tablet) {
    .main-home {
        height: calc(100vh - $heigth-navbar-small);
        .img-in-construction {
            width: 22rem;
            height: auto;
        }
        .p1 {
            padding: 0;
        }
        .div-contact {
            .p2 {
                font-size: 20px;
                padding: 2rem 0;
                text-align: center;
            }
        }
    }
}

// @media  screen and (max-width: $mobile) {
//     .main-home {
//         .img-in-construction {
//             width: 20rem;
//             height: auto;
//         }
//     }
// }